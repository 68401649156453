import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`Use a paper when you want a depth difference between the page and your wrapped
component(s). Design wise you can relate this with containers in a design tool
e.g.
`}<a parentName="p" {...{
        "href": "https://help.figma.com/hc/en-us/articles/360041539473-Frames-in-Figma"
      }}>{`Frames`}</a>{`
in Figma.`}</p>
    <h2 {...{
      "id": "options",
      "style": {
        "position": "relative"
      }
    }}>{`Options`}</h2>
    <h3 {...{
      "id": "elevation",
      "style": {
        "position": "relative"
      }
    }}>{`Elevation`}</h3>
    <p>{`Ranging from level -1 to 3, a drop shadow increases the paper's relative depth.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex hideEditor",
        "wrap": "flex",
        "hideEditor": true
      }}>{`<Paper elevation={-1} p={4} m={2} height='100px' width="100%" display="flex" justifyContent="center" flexDirection="column" textAlign="center"><Caption color="grey80" textAlign="center">Elevation -1</Caption></Paper>
<Paper elevation={0} p={4} m={2} height='100px' width="100%" display="flex" justifyContent="center" flexDirection="column"><Caption color="grey80" textAlign="center">Elevation 0</Caption></Paper>
<Paper elevation={1} p={4} m={2} height='100px' width="100%" display="flex" justifyContent="center" flexDirection="column"><Caption color="grey80" textAlign="center">Elevation 1</Caption></Paper>
<Paper elevation={2} p={4} m={2} height='100px' width="100%" display="flex" justifyContent="center" flexDirection="column"><Caption color="grey80" textAlign="center">Elevation 2</Caption></Paper>
<Paper elevation={3} p={4} m={2} height='100px' width="100%" display="flex" justifyContent="center" flexDirection="column"><Caption color="grey80" textAlign="center">Elevation 3</Caption></Paper>
`}</code></pre>
    <h3 {...{
      "id": "border-radius",
      "style": {
        "position": "relative"
      }
    }}>{`Border radius`}</h3>
    <p>{`Every paper can be set with a border radius value from
`}<a parentName="p" {...{
        "href": "/foundations/designtokens"
      }}>{`Chameleon Design Tokens`}</a>{`. The default value is
`}<inlineCode parentName="p">{`borderRadius1`}</inlineCode>{` which corresponds with 2px.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex hideEditor",
        "wrap": "flex",
        "hideEditor": true
      }}>{`<Paper
  elevation={2}
  borderRadius={0}
  p={4}
  m={2}
  height="100px"
  width="100%"
  display="flex"
  justifyContent="center"
  flexDirection="column"
  textAlign="center"
>
  <Caption color="grey80" textAlign="center">
    Border radius 0
  </Caption>
</Paper>
<Paper
  elevation={2}
  borderRadius={1}
  p={4}
  m={2}
  height="100px"
  width="100%"
  display="flex"
  justifyContent="center"
  flexDirection="column"
  textAlign="center"
>
  <Caption color="grey80" textAlign="center">
    Border radius 1
  </Caption>
</Paper>
<Paper
  elevation={2}
  borderRadius={4}
  p={4}
  m={2}
  height="100px"
  width="100%"
  display="flex"
  justifyContent="center"
  flexDirection="column"
  textAlign="center"
>
  <Caption color="grey80" textAlign="center">
    Border radius 4
  </Caption>
</Paper>
`}</code></pre>
    <h3 {...{
      "id": "hoverable",
      "style": {
        "position": "relative"
      }
    }}>{`Hoverable`}</h3>
    <p>{`To indicate to users that a paper has an action or a link bound to it, it can
have a hoverable behaviour. This will increase the paper's depth when a user
mouses over it.`}</p>
    <p>{`When a paper is hoverable, the box shadow automatically increases with one
level.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=flex hideEditor",
        "wrap": "flex",
        "hideEditor": true
      }}>{`<Paper elevation={2} p={4} m={2} height='100px' width="100%" display="flex" justifyContent="center" flexDirection="column"><Caption color="grey80" textAlign="center">This paper is not hoverable</Caption></Paper>
<Paper hoverable elevation={2} p={4} m={2} height='100px' width="100%" display="flex" justifyContent="center" flexDirection="column"><Caption color="grey80" textAlign="center">This paper is hoverable</Caption></Paper>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      